@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');
@import "assets/css/util.css";
@import "assets/css/imported.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

html, body { height: 100%; }
body { margin: 0;   font-family: 'Montserrat', sans-serif !important;}

*{
  font-family: 'Montserrat', sans-serif;
}

.material-icons{
  font-family: 'Material Icons' !important;
}

.row{
  --bs-gutter-x: 0;
}
.progress-container{
  margin-top: 32px;
}

.dark{
  color: #B9C4D1;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #d0caca;
  opacity: 1; /* Firefox */
}

.input-grey {
  background-color: #636874;
  border: none;
  border-radius: 11px;
  min-height: 48px;
  width: 100%;
  padding: 4px 8px;
  font-size: medium;
  color: white !important;
  transition: color .2s ease,background-color .2s ease;
  //min-width: 429px;
}

.select-box {
  background-color: #636874;
  border-radius: 12px;
  min-height: 48px;
  width: 100%;
  padding: 4px 8px;
  font-size: medium;
  color: white !important;
  cursor: pointer;
  transition: color .2s ease,background-color .2s ease;
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  //border-radius: 15px!important;
}
.input-grey:focus {
  outline: none !important;
  background-color: #636874;
}

.form-section{
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 12px;
  margin-top: 8px;
}



textarea:focus, input:focus{
  outline: none !important;
}

.link-primary{
  text-decoration: none!important;
}

.initial{
  padding: 6px 10px !important;
  font-weight: 600 !important;
  /* margin-right: 7px; */
  border-radius: 5px !important;
  margin-right: 10px !important;
}

.status {
  background-color: #eee5ff;
  padding: 5px 10px !important;
  border-radius: 6px;
  color: #b49efd;
  font-size: 14px;
}

.role-chip-group{
  padding: 4px 8px !important;
  border-radius: 12px;
  background-color: rgb(253, 241, 214);
  color: rgb(254, 208, 73);
  font-size: small;
  margin-right: 8px;
}


.role-chip-admin{
  background-color: #eee5ff;
  padding: 4px 8px !important;
  border-radius: 12px;
  color: #b49efd;
  font-size: small;
  margin-right: 8px;
}

.full-dialog-container .mat-dialog-container {
  padding: 0 !important;
}

.drop{
  border: 1px dashed #009ef7;
  min-height: 180px;
  border-radius: 12px;
}

.drop-class{
  padding-top: 24px;
}


mat-sidenav {
  width: 200px;
}


.mat-expansion-panel-content > .mat-expansion-panel-body {
  padding: 0 !important;
}
//
//.swal2-actions{
//  width: 100% !important;
//  padding-right: 16px!important;
//  justify-content: end !important;
//}

.custom-dialog-container .mat-dialog-container {
  padding: 0;
}


.dialog-header{
  position: sticky;
  top: 0;
  background-color: #fafafc;
  width: 100%;
  z-index: 99;
  padding: 16px;
}


.dialog-button{
  position: sticky;
  background-color: #fafafc;
  bottom: 0;
  padding: 16px;
  text-align: right;
  right: 0;
}

.dialog-button-no-padding{
  position: sticky;
  bottom: 0;
  text-align: right;
  right: 0;
  top: 16px;
  padding-top: 16px;
}

.items-container {
  border-radius: 20px;
  text-align: left;
  padding-left: 24px;
  height: calc(100vh - 100px);
  overflow-y: hidden;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.item-details-heading{
  padding: 16px;
  color: #636874;
  border-bottom: 1px solid #636874;
}

.item-heading {
  padding: 16px;
  font-size: large;
  margin-left: 18px;
  margin-right: 18px;
}

.item-holder {
  min-height: 100vh;
  padding-right: 8px;
  overflow-y: scroll;
  border-right: solid 1px #d9d9d9;
}

.item-card {
  cursor: pointer;
}

.details-holder {
  text-align: center;
  padding: 8px 16px;
}

.empty-state {
  color: #767676;
  margin-top: 48px;
}

.image-card {
  min-height: 120px;
  display: inline-block;
  min-width: 120px;
  margin-right: 16px;
  padding-top: 16px;
}

.image-cards {
  //margin: 0 auto;
  //display: grid;
  //gap: 1rem;
  //
  margin: 4px 4px;
  padding: 4px;
  //width: 100%;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.image-section {
  border-radius: 16px;
  padding: 16px;
  background-color: #f0f0f0;
}

.expansion-container {
  padding: 16px;
}

.expansion {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.add-img{
  object-fit: cover;
  width: 120px;
  height: 120px;
}

.image-card{
  display: inline-block;
  width: 120px;
  margin-right: 16px;
  padding: 0;
  height: 120px;
  border-radius: 12px;
}

.image-cards {
  //margin: 0 auto;
  //display: grid;
  //gap: 1rem;
  //
  margin: 4px 4px;
  padding: 4px;
  display: flex;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  //justify-content: center;
  align-items: center;
}

.image-section{
  border-radius: 16px;
  padding: 16px;
  background-color: #f0f0f0;
}

.toggle-container{
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  display: grid;
}
.add-card{
  text-align: left;
  border-radius: 16px;
  padding: 24px;
  width: 100%;
  height: auto;
}

.add-card-image{
  min-width: 140px;
  min-height: 140px;
  text-align: center;
}

.content-holder{
  height: 72vh;
  overflow-x: scroll;
  padding: 12px;
}

.btn-holder{
  padding: 16px;
  position: sticky;
  top: 0;
}

.primary-red-btn{
  margin: 16px;
  padding-left: 32px !important;
  padding-right: 32px !important;
  color: whitesmoke;
  background-color: #B40031 !important;
  border-radius: 32px !important;
  //background-color: #111111;
}

.primary-yellow-btn{
  margin: 16px;
  padding-left: 32px !important;
  padding-right: 32px !important;
  color: whitesmoke;
  background-color: #f8a115 !important;
  border-radius: 32px !important;
  //background-color: #111111;
}

.save-btn{
  margin: 16px;
  padding-left: 32px !important;
  padding-right: 32px !important;
  color: whitesmoke;
  background-color: #d60b0b;
  border-radius: 32px !important;
  //background-color: #111111;
}

.black-btn{
  margin: 16px;
  padding-left: 32px !important;
  padding-right: 32px !important;
  color: whitesmoke;
  background-color: #212121;
  border-radius: 32px !important;
  //background-color: #111111;
}

.white-col{
  color: #FAFAFA !important;
}


.cancel-btn{
  margin: 16px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  color: #d60b0b;
  border-radius: 16px !important;
}

.thumbnail {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
}

.color-grey{
  color: #767676;
}

.time-txt{
  text-decoration: underline;
  color: #B40031;
  padding: 4px;
}

.delete-stroked-btn{
  color: #d60b0b !important;
  border: #d60b0b solid 1px !important;
}

.mat-header-cell {
  flex-direction: column !important;
  justify-content: center !important;
}

.mat-cell {
  text-align: center !important;
  justify-content:center !important;
}

.mat-mdc-snack-bar-container {
  &.red-snackbar {
    --mdc-snackbar-container-color: #ffe2e5;
    --mat-mdc-snack-bar-button-color: #f64e60;
    --mdc-snackbar-supporting-text-color: #f64e60;
  }
}

.mat-mdc-snack-bar-container {
  &.green-snackbar {
    --mdc-snackbar-container-color: #1fab89;
    --mat-mdc-snack-bar-button-color: #f3f4ed;
    --mdc-snackbar-supporting-text-color: #f3f4ed;
  }
}

.mat-mdc-snack-bar-container {
  &.dark-snackbar {
    --mdc-snackbar-container-color: #536162;
    --mat-mdc-snack-bar-button-color: #f3f4ed;
    --mdc-snackbar-supporting-text-color: #f3f4ed;
  }
}

.mat-mdc-snack-bar-container {
  &.regular-snackbar {
    --mdc-snackbar-container-color: #f3f4ed;
    --mat-mdc-snack-bar-button-color: #536162;
    --mdc-snackbar-supporting-text-color: #536162;
  }
}

.green-toast{
  color: #f3f4ed !important;
  background-color: #1fab89;
}
.regular-toast{
  color: #536162 !important;
  background-color: #f3f4ed;
}
.red-toast{
  color: #f64e60 !important;
  background-color: #ffe2e5;
}

.container-card{
  padding: 16px;
  border-radius: 16px !important;
  margin: 16px;
}

.m-0{
  margin: 0 !important;
}

.x-scroll{
  overflow-x: scroll;
}
.p-r{
  position: relative;
}

.item-card{
  color: #ECECEC;
  margin: 8px;
  padding: 4px;
  border-bottom: 1px solid #767676;
  //border-radius: 16px !important;
}

.item-card-selected{
  background-color: #B9C4D1;
  margin: 8px;
  padding: 12px;
  border-radius: 16px !important;
  border-bottom: 1px solid #767676;
}

.page-inner-container{
  padding-top: 16px;
  padding-left: 8px;
  padding-right: 16px;
}

.heading{
  color: #ECECEC;
  padding: 12px;
}

.list-container{
  overflow-y: scroll;
  height: calc(100vh - 100px);
  border-right: 1px solid #767676;
}

.details-container{
  overflow-y: scroll;
  height: calc(100vh - 100px);
  border-right: 1px solid #767676;
}

.list-title{
  font-size: medium;
  margin-bottom: 4px;
}

.input-container{
  margin: 12px;
}

.input-label{
  font-size: small;
  margin-left: 4px;
  margin-bottom: 8px;
  color: #e0e0e0;
}

.empty-details{
  padding-top: 64px;
  text-align: center;
  color: #636874;
}

.btn-primary-app{
  font-size: medium;
  padding: 4px 16px !important;
  border-radius: 8px !important;
  min-width: 180px !important;
  min-height: 36px !important;
  background-color: #b9c4d1 !important;
  color: #212121 !important;
}

.doc-id{
  cursor: pointer;
  text-align: center;
  padding: 16px;
  font-size: small;
  color: #5E6278;
}

.grid {
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(140px, 140px));
  display: grid;
}

.grid-item {
  border-radius: 8px;
  padding: 0;
  height: 140px;
  cursor: pointer;
}

.grid-card-item {
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
}

.card-item{
  border-radius: 8px;
  padding: 0 !important;
  height: 140px;
  cursor: pointer;
}

.thumbnail {
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: 8px;
}

.page-content{
  //padding: 24px;
}

h1,h2,h3,h4,h5,h6{
  color: #f0f0f0;
}
.icon-st{
  font-size: 32px;
  color: #767676;
}
.icon-large{
  font-size: 72px;
  color: #767676;
}

.icon-sml{
  font-size: 22px;
  color: #494949;
}

.header-section {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 12px;
  margin: 8px;
  border-bottom: 1px solid #767676;
}

.btn-container{
  padding: 16px;
  margin: 12px !important;
}

.form-icon{
  height: 48px;
  border-radius: 12px;
}

.form-card{
  background-color: #101525 !important;
  border-radius: 12px !important;
  padding: 12px !important;
  color: #f0f0f0;
  margin: 8px;
}

.form-layout{
  border-radius: 12px !important;
  padding: 12px !important;
  color: #f0f0f0;
  margin: 8px;
}

.form-card-heading{
  margin-top: 8px;
  margin-left: 4px;
}

.form-padding{
  padding: 16px;
}
.modal-padding{
  padding: 36px;
}

.white-btn{
  background-color: #f0f0f0 !important;
  color: #0b0e18 !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  border-radius: 24px !important;
}

.white-btn:disabled{
  background-color: #c0bebe !important;
  color: #f0f0f0 !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  border-radius: 24px !important;
}

.white-btn:hover{
  background-color: #d0d0d0 !important;
  color: #0b0e18 !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

//.btn-primary-app {
//  background-color: #636874 !important;
//  color: #fff !important;
//  font-size: medium;
//  border-radius: 12px;
//}

.custom-container .mat-dialog-container {
  border-radius: 16px !important;
}

.menu-select{
  border: 1px solid #767676;
  padding: 12px;
  font-size: small;
  color: #d9d9d9;
  border-radius: 24px;
  cursor: pointer;
  margin-left: 12px;
  margin-right: 12px;
}

.orange-btn{
  background-color: #f8a115 !important;
  color: white !important;
  width: fit-content !important;
  padding-left: 28px !important;
  padding-right: 28px !important;
  border-radius: 16px !important;
  margin: 8px !important;
}

.outline-rounded{
  padding: 12px;
  border-radius: 12px;
  border: 1px solid #767676;
}

.backdrop{
  background: rgba(16, 21, 37, 0.8);
}

.primary-btn{
  color: whitesmoke;
  background-color: #212121;
  padding-left: 16px !important;
  padding-right: 16px !important;
  border-radius: 24px !important;
}

.primary-btn:disabled{
  background-color: #c0bebe !important;
  color: #f0f0f0 !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  border-radius: 24px !important;
}
